'use client';

import React from 'react';
import fallbackIcon from '@frontend/jetlend-assets/icons/icon--404.svg';
import { getAssetSrc } from '@ui/utils/getAssetSrc';
import PlaceholderWrapper, { IProps as PlaceholderWrapperProps } from '../PlaceholderWrapper/PlaceholderWrapper';

export type IProps = Pick<PlaceholderWrapperProps, 'startPageLocation'|'onHomeClicked'|'image'>

const FallbackPlaceholder: React.FC<IProps> = props => (
    <div className="d-flex d-flex--center d-flex--1 d-flex--v-center">
        <PlaceholderWrapper
            startPageLocation={props.startPageLocation}
            image={getAssetSrc(fallbackIcon)}
        >
            <h2 className="h-mt-20 h-mb-30">Страница не найдена</h2>
        </PlaceholderWrapper>
    </div>
);

export default FallbackPlaceholder;